const content = `
date	type	title	description	photoURL	caption
609	R	The Revelation Begins	Archangel Gabriel meets Prophet Muhammad. Prophet Muhammad runs to his wife [Khadija bint Khwaylid](https://en.wikipedia.org/wiki/Khadija_bint_Khuwaylid) who introduces him to [Waraqa ibn Nawfal](https://en.wikipedia.org/wiki/Waraqah_ibn_Nawfal), a [hanif](https://en.wikipedia.org/wiki/Hanif) adhering to the religion of Abraham.	https://upload.wikimedia.org/wikipedia/commons/e/e4/Dark_vignette_Al-Masjid_AL-Nabawi_Door800x600x300.jpg	Dark vignette Al-Masjid AL-Nabawi
622	R	First Islamic Society	Prophet Muhammad goes on [Hegira](https://en.wikipedia.org/wiki/Hegira) (departure from Mecca to Medina). He's welcomed by the people of Medina. The event marks the birth of the Islamic Society.	https://i.ibb.co/93pG7b8/desert-4134934-1920.jpg	Camels
632	R	Rashidun Caliphate Starts	Prophet Mohammed dies having conqured Mecca. Most tribes of Arabia are Muslims.	https://upload.wikimedia.org/wikipedia/commons/d/d4/Tile_panel_from_Damascus%2C_c._1600%2C_Boston_Museum_of_Fine_Arts.jpg	Tile panel from Damascus with the names of the four rightly guided caliphs.
634	C	Expansion Of The Caliphate	Omar ibn Al-Khattab becomes second Caliphate. During his reign, the Caliphate expands from North Africa to Persia.	https://i.ibb.co/7vjhvNn/Great-Mosque-of-Kairouan-courtyard-and-prayer-hall-facade.jpg	The expansion of the Rushidun Caliphate
641	C	Egypt, Land Of Islam	Muslim conquest of Egypt is complete after the siege of Alexandria (the capital at the time) ran for months, marking the end of Eastern Roman maritime power. Muslims considered their conquest as the liberation of subjugated people, since in most instances they were under oppressive rule.	https://upload.wikimedia.org/wikipedia/commons/3/31/Mosque-Madrassa_of_Sultan_Hassan_-_Cairo_2.jpg	Roman ruins in Alexandria remain to this day
642	C	A Decisive Victory In Nahavand	Muslim's victory in the Battle of Nahavand laid the groundwork for ending the Sassanid Empire and the conquest of all Persia.	https://upload.wikimedia.org/wikipedia/commons/d/d8/Castle_Nahavend_by_Eug%C3%A8ne_Flandin.jpg	 Nahavand Castle, which was one of the last Sasanian strongholds
656	C	Civil War Breaks Out	Uthman Ibn Affan is assassinated by the Kharajites. The first fitna, civil war, begins between different factions of Muslims.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
661	C	Ummayads Rise To Power	[Muawiyah ibn Abi Sufyan](https://en.wikipedia.org/wiki/Muawiyah_I) becomes the first Umayyad Caliph after the peace treaty with Hassan ibn Ali, grandson of the Prophet Muhammad and last caliph in Rashidun Caliphate.	https://upload.wikimedia.org/wikipedia/commons/0/02/Lead_seal_of_Mu%27awiya%27s_dismissal_of_Ibn_Amir%2C_ca._664.png	Ummayad Seal
680	R	The Battle Of Karbala	[Husayn ibn Ali](https://en.wikipedia.org/wiki/Husayn_ibn_Ali), the grandson of Prophet Muhammad, is killed in the battle of [Karbala](https://en.wikipedia.org/wiki/Battle_of_Karbala), a day remembered by Shia Muslims to this day.	https://upload.wikimedia.org/wikipedia/commons/4/45/Karbala_City_3.jpg	test caption
692	C	The Siege Of Mecca	Second fitna, civil war ends, Abd Allah ibn al-Zubayr is martyred after the siege of Mecca.	https://i.ibb.co/q9c706Z/mecca.jpg	Ka'abaa
695	C	Abd Al-Malik Ibn Marwan Creates The Empire	[Abd al-Malik ibn Marwan](https://en.wikipedia.org/wiki/Abd_al-Malik_ibn_Marwan) makes major reforms in the administrative structure of the Caliphate, including increasing caliphal power, restructuring the army and Arabizing and Islamizing the bureaucracy. He also minted the first Islamic gold Dinar. His reforms laid the groundwork for trade and exchange for centries to come.	https://i.ibb.co/jLmx5VL/dm.jpg	test caption
699	R	Ahl Al-Ra'y In Iraq	The birth of "the great imam" of Sunnis, [Abu Hanifa](https://en.wikipedia.org/wiki/Abu_Hanifa), a theologian and jurist of Persian origin. His school of jurisprudence is the most widely practiced to this day.	https://upload.wikimedia.org/wikipedia/commons/3/34/%D8%AC%D8%A7%D9%85%D8%B9_%D8%A7%D9%84%D8%A5%D9%85%D8%A7%D9%85_%D8%A7%D9%84%D8%A3%D8%B9%D8%B8%D9%85_%D8%A3%D8%A8%D9%8A_%D8%AD%D9%86%D9%8A%D9%81%D8%A9.jpg	test caption
705	C	Poetry Is Truly Arab	The rise of poetry under the Umayyad rule. [Al-Farazdaq](https://en.wikipedia.org/wiki/Al-Farazdaq) makes it to the personal court of [Caliph al-Walid](https://en.wikipedia.org/wiki/Al-Walid_I). [Jareer](https://en.wikipedia.org/wiki/Jarir_ibn_Atiyah) and [Al-Akhtal](https://en.wikipedia.org/wiki/Al-Akhtal_al-Taghlibi)'s poems survive to this day.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
711	C	Islam Reaches Europe	[Tariq ibn Ziyad](https://en.wikipedia.org/wiki/Tariq_ibn_Ziyad) disembarks on a journey to cross the Mediterranean Sea, defeats the Visigoths, and start the muslim conquest of the Iberian peninsula.	https://i.ibb.co/mzwwqZX/alhambra-179171-1920.jpg	test caption
717	R	The sixth Rashid Caliph	[Omar ibn Abd al-Aziz](https://en.wikipedia.org/wiki/Umar_II) rises to power, deemed as the sixth Rashid (rightly-guided) Caliphate. He was a pacifist who cared more spreading Islam than the conquests.	https://upload.wikimedia.org/wikipedia/commons/4/4a/Gold_dinar_of_Umar_II.jpg	test caption
750	C	The Rise Of The Abbasids	The  [Abbasids](https://en.wikipedia.org/wiki/Abbasid_Caliphate) revolt in [Khorsan](https://en.wikipedia.org/wiki/Greater_Khorasan) by virtue of their closer bloodline to Muhammad. The last Ummayad caliph Marwan flees to Egypt, where he was subsequently assassinated. [As-Saffah](https://en.wikipedia.org/wiki/As-Saffah) becomes the first Abbasid caliph. The Caliphate moves from  Damascus to Baghdad.	https://upload.wikimedia.org/wikipedia/commons/2/2b/Samara_spiralovity_minaret_rijen1973.jpg	The Grand Mosque of Samarra
763	C	Ummayad Europe Lives On	[Caliph Al-Mansur](https://en.wikipedia.org/wiki/Al-Mansur), accepting defeat, dubbs [Abd al-Rahman I of Andalus](https://en.wikipedia.org/wiki/Abd_al-Rahman_I)  "Hawk of Quraysh" and promises never to fight him again. Abbasids would never control Andalus and it would remain under the control of the Ummayads for a few hundered years until its dissolution.	https://upload.wikimedia.org/wikipedia/commons/0/0e/Abd_ar-Rahman_I.jpg	test caption
779	R	Hadith Collection	Caliph Al-Mansur requests Imam Malik to produce a standard book that could be promulgated as law in the country. Imam Malik writes his Muwatta, the earliest collection of hadith texts comprising the subjects of Islamic law.	https://upload.wikimedia.org/wikipedia/commons/d/de/Imam_malik.png	test caption
786	S	Harun Al-Rashid and the House Of Wisdom	Harun al-Rashid (the rightly guided) comes to power. He creates Bayt Al-Hikmah, House of Wisdom,  as a part of the significant Translation Movement taking place during the Abbasid Era, translating works from Greek and Syriac to Arabic.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
813	C	The Great Caliph	Al-Ma'mun wins the civil war and becomes caliph. A well-educated caliph that sought reconciliation between Greek philosophy and theological questions like the problem of evil and the speech of God, he adopted the [Muʿtazila](https://en.wikipedia.org/wiki/Mu%CA%BFtazila), the rationalist school of theology, that stressed free will and supremacy of human reason over scripture.	https://upload.wikimedia.org/wikipedia/commons/2/2a/Astrolabe_planisf%C3%A9rique_closeup800x600x300.jpg	test caption
827	R	The Mihna Of Qur'an Createdness	The caliph al-Ma’mun issued the proclamation of the doctrine of the createdness of the Qur'an. [The Mihna](https://en.wikipedia.org/wiki/Mihna), Arabic for tests, begins. Religious persecution lasts for 15 years. [Imam Ahmad ibn Hanbal](https://en.wikipedia.org/wiki/Ahmad_ibn_Hanbal) becomes a symbol of the orthodoxy and eventually triumphs.	https://upload.wikimedia.org/wikipedia/commons/f/f5/Quran2.png	test caption
838	S	The Great Exegesis	[Al-Tabari](https://en.wikipedia.org/wiki/Al-Tabari) is born, an influential Persian scholar, historian and exegete of the Qur'an from Tabaristan (Iran). He writes two of the most influential books in all Islamic History: [Tafsir al-Tabari](https://en.wikipedia.org/wiki/Tafsir_al-Tabari), a Qur'anic commentary, and [Tarikh al-Tabari](https://en.wikipedia.org/wiki/History_of_the_Prophets_and_Kings), a historical chronicle.	https://upload.wikimedia.org/wikipedia/commons/e/ee/Qur%27an_Tabari.jpg	Tabari's Exegesis
842	R	Musnad Imam Ahmad	[Imam Ahmad ibn Hanbal] (https://en.wikipedia.org/wiki/Ahmad_ibn_Hanbal) finishes his [Musnad](https://en.wikipedia.org/wiki/Musnad_Ahmad_ibn_Hanbal), the second great compilation of Hadith in Islamic history at the time.	https://upload.wikimedia.org/wikipedia/commons/2/2f/Ahmad_ibn_Hanbal_%28calligraphic%2C_transparent_background%29.png	test caption
846	R	The Most Authentic Book Of Hadith	[Muhammad al-Bukhari](https://en.wikipedia.org/wiki/Muhammad_al-Bukhari) finishes his [Saheeh](https://en.wikipedia.org/wiki/Sahih_al-Bukhari), the most authentic book of six most authentic books of [Hadith](https://en.wikipedia.org/wiki/Kutub_al-Sittah).	https://upload.wikimedia.org/wikipedia/commons/4/4b/ImamBukhari1.png	test caption
872	S	The Second Teacher	[Al-Farabi](https://en.wikipedia.org/wiki/Al-Farabi) is born in Greater Khorasan (modern day Afghanistan). In Islamic philosophical tradition, he is often called "the Second Teacher", Aristotle being the first. His commentaries and treatises on Aristotle and Plato influenced many prominent philosophers like Ibn Sina [Avicenna].	https://upload.wikimedia.org/wikipedia/commons/5/55/Al-Farabi.jpg	test caption
912	C	The Greatest Caliph Of Andalus	[Abd al-Rahman III](https://en.wikipedia.org/wiki/Abd_al-Rahman_III) rise to power in Andalus. It's said that he was the greatest of the Umayyad rulers of Andalus. He's known for his quote: "I have now reigned above fifty years in victory or peace; beloved by my subjects, dreaded by my enemies, and respected by my allies. Riches and honors, power and pleasure, have waited on my call, nor does any earthly blessing appear to have been wanting to my felicity. In this situation, I have diligently numbered the days of pure and genuine happiness which have fallen to my lot: they amount to Fourteen: - O man! Place not thy confidence in this present world!"	https://upload.wikimedia.org/wikipedia/commons/5/57/Spain_Andalusia_Cordoba_BW_2015-10-27_13-54-14.jpg	Mosque–Cathedral of Córdoba
915	S	The Most Celebrated Of Poets	[Al-Mutanabbi](https://en.wikipedia.org/wiki/Al-Mutanabbi) best Arabic poet of all time is born in Kufa. His most prolific work comes when he's patronised by [Sayf al-Dawla](https://en.wikipedia.org/wiki/Sayf_al-Dawla) Emir of Aleppo.	https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/%D8%A7%D8%A8%D9%88_%D8%A7%D9%84%D8%B7%D9%8A%D8%A8_%D8%A7%D9%84%D9%85%D8%AA%D9%86%D8%A8%D9%8A.jpg/800px-%D8%A7%D8%A8%D9%88_%D8%A7%D9%84%D8%B7%D9%8A%D8%A8_%D8%A7%D9%84%D9%85%D8%AA%D9%86%D8%A8%D9%8A.jpg	test caption
969	C	A Shia Caliphate In Egypt	Ismaili [Fatimids] (https://en.wikipedia.org/wiki/Fatimid_Caliphate) conquer Egypt and start a great Caliphate of their own to rival the deteriorating Abbasid Caliphate. At this point, three Caliphates exist, Ummayad in Cordoba, spain, Abbasid in Baghdad, and Fatimids in Cairo.	https://i.ibb.co/VvsQrLH/al-azhar-2292512-1920.jpg	Al-Azhar, the biggest islamic university, was built to spread the Shia teaching. Today, it's the biggest Sunni defender.
996	C	The Mad Caliph	[Al-Hakim bi-Amr Allah](https://en.wikipedia.org/wiki/Al-Hakim_bi-Amr_Allah), known in the west as the mad caliph, becomes the caliph of the Fatimids. He's the 16th Ismaili imam and an important figure to the Druze (that only lived on in the Levant).	https://upload.wikimedia.org/wikipedia/commons/3/3e/Al-Hakim.jpg	test caption
980	S	Ibn Sina, The Prominant Philosopher	[Ibn Sina](https://en.wikipedia.org/wiki/Avicenna), known in the west as Avicenna, is born in Bukhara. A polymath that got bored of medicine early in his life, he spent his time immersed in philosophy and as all his contemprories proving that philosophy and religion lead to the same conclusions and the ultimate oneness of God. He wrote the Cannon in medicine, the best book in medicine for at least a few hundred years. He contributed to astronomy, alchemy, geography and geology, psychology, Islamic theology, logic, mathematics, physics and works of poetry.	https://upload.wikimedia.org/wikipedia/commons/f/f3/Persian_Scholar_pavilion_in_Viena_UN_%28Avicenna%29.jpg	test caption
1031	C	The Ummayads In Exile Fall	Ummayad Caliphate in Andalus falls and disolves into Taifa kingdoms.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
1040	C	Rise Of Al-Moravids	[Al-Moravids](https://en.wikipedia.org/wiki/Almoravid_dynasty) are the first the Berber Muslim Dynasty in West Africa. They've taken [Marrakech](https://en.wikipedia.org/wiki/Marrakesh) as their capital which was founded by their most prominent Sultan [Yusuf Ibn Tashfin](https://en.wikipedia.org/wiki/Yusuf_ibn_Tashfin) who at the time got legitimacy by getting the permission of Abbasid Caliph to rule west Africa.	https://upload.wikimedia.org/wikipedia/commons/d/d8/Yusuf-bin-Tashfin-tomb.jpg	Yusuf Ibn Tashfin's tomb
1058	R	Al-Ghazali Reviivng The Religion And Attacking Philosophers	[Al-Ghazali](https://en.wikipedia.org/wiki/Al-Ghazali) is born in Tus, one of the most prominent thrologians and jurists in the history of Islam. Among many books, two books stand out. In the east, ["The Revival of the Religious Sciences"](https://en.wikipedia.org/wiki/The_Revival_of_the_Religious_Sciences) is considered the most notable of his work and is still studied in [Halaqs](https://en.wikipedia.org/wiki/Halaqa) to this day. In the west, ["The Incoherence of the Philosophers"](https://en.wikipedia.org/wiki/The_Incoherence_of_the_Philosophers) is the most well-known book, where he refutes many arguments of the great Philosopher Ibn Sina.	https://upload.wikimedia.org/wikipedia/commons/e/e9/%D8%A3%D8%A8%D9%88_%D8%AD%D8%A7%D9%85%D8%AF_%D8%A7%D9%84%D8%BA%D8%B2%D8%A7%D9%84%D9%8A.png	test caption
1064	C	The Persian Bureaucrat	[Nizam al-Mulk](https://en.wikipedia.org/wiki/Nizam_al-Mulk) becomes vizier of the [Seljuk Empire](https://en.wikipedia.org/wiki/Seljuk_Empire), he was the first person to start the madrasa system called [Nizamiyeh](https://en.wikipedia.org/wiki/Nezamiyeh), first well organized institutions of higher learning in the Muslim world, at the time. Nizam al-Mulk asks Al-Ghazali to lead it.	https://upload.wikimedia.org/wikipedia/commons/a/af/Assassination_of_Nizam_al-Mulk.jpg	test caption
1071	C	A Muslim Anatolia	A decisive win for the Suljuks under [Alp Arselan](https://en.wikipedia.org/wiki/Alp_Arslan) over the [Byzantines](https://en.wikipedia.org/wiki/Byzantine_Greeks) in the battle of [Manzikert](https://en.wikipedia.org/wiki/Battle_of_Manzikert) is still celebrated to this day in Turkey.	https://upload.wikimedia.org/wikipedia/commons/5/5e/Alp_arslan_sultan.jpg	Alp Arslan
1121	C	The Rise of Al-Mohad Caliphate	The Almohad movement was founded by Ibn Tumart among the Berber Masmuda tribes in the south of modern Morocco. Around 1120, the Almohads first established a Berber state in Tinmel in the Atlas Mountains.	https://upload.wikimedia.org/wikipedia/commons/3/3d/Sevilla_Almohade.JPG	test caption
1126	S	Ibn Rushd, The Polymath	[Ibn Rushd (Averroes)](https://en.wikipedia.org/wiki/Averroes) is born in Cordoba. A polymath and jurist who wrote about many subjects, including philosophy, medicine, astronomy. A strong proponent of Aristotelianism. Ibn Rushd defends philosophers and writes his infamous ["Incoherence of the Incoherence"](https://en.wikipedia.org/wiki/The_Incoherence_of_the_Incoherence) to reply to Al-Ghazali's arguments.	https://upload.wikimedia.org/wikipedia/commons/a/a2/Statue_of_Averroes_in_C%C3%B3rdoba%2C_Spain.jpg	test caption
1174	C	Salah ad-Din	[Salah ad-Din](https://en.wikipedia.org/wiki/Saladin) rises through the ranks to become sunni vizier to the caliph of [Fatimids](https://en.wikipedia.org/wiki/Fatimid_Caliphate) abolishes the Ismaili Caliphate and brings Egypt's rule under the Abbasid Caliphate once age. He founds the [Ayyubid dynasty](https://en.wikipedia.org/wiki/Ayyubid_dynasty) which stretches from Iraq to Morocco.	https://upload.wikimedia.org/wikipedia/commons/6/64/Statue_of_Saladin_Damascus.jpg	Statue of Saladin in Damascus
1187	C	The Battle Of Hattin	The Muslim armies under Saladin free Jerusalem from the crusaders and bring the Holy Land under the Muslim control once again.	https://upload.wikimedia.org/wikipedia/commons/e/e3/Saladin_and_Guy.jpg	test caption
1250	C	Sultana Shajar Al-Durr	[As-Salih](https://en.wikipedia.org/wiki/As-Salih_Ayyub) dies during the [seventh crusades] (https://en.wikipedia.org/wiki/Seventh_Crusade). His wife [Shajar al-Durr](https://en.wikipedia.org/wiki/Shajar_al-Durr) becomes Sultana. She then marries [Aybek](https://en.wikipedia.org/wiki/Aybak) whom she kills. Later on, she's beaten to death with clogs by the bondmaids of Om Ali.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
1250	C	Mamluks Sultanate	[Shajar al-Durr](https://en.wikipedia.org/wiki/Shajar_al-Durr) with her second husband [Aybek](https://en.wikipedia.org/wiki/Aybak) create the Mamluks Sultanate. A sultanate made up of slave warriors who become Sultans based on their merit and bringing the factions together rather than bloodline. The Sultanate is so effective that it destroys the crusades and the mongols once and for all.	https://upload.wikimedia.org/wikipedia/commons/3/31/Mosque-Madrassa_of_Sultan_Hassan_-_Cairo_2.jpg	Sultan Hassan Mosque
1258	C	The Siege of Baghdad	(..)[https://en.wikipedia.org/wiki/Siege_of_Baghdad_(1258)]	https://upload.wikimedia.org/wikipedia/commons/8/85/Bagdad1258.jpg	Mongols depicition of the siege
1260	C	Ain Jalut	Qutuz, mamluk Sultan of Egypt, destroys the mongol army in [the battle of Ain Jalut](https://en.wikipedia.org/wiki/Battle_of_Ain_Jalut) in Palestine.	https://upload.wikimedia.org/wikipedia/commons/1/1f/Mameluke_Flag.svg	Mamluks Flag
1260	C	Baibars	The Greatest Sultan [Baibars](https://en.wikipedia.org/wiki/Baibars) rise to power.	https://upload.wikimedia.org/wikipedia/commons/a/a4/%D8%AA%D9%85%D8%AB%D8%A7%D9%84_%D9%84%D9%84%D8%B3%D9%84%D8%B7%D8%A7%D9%86_%D8%A7%D9%84%D8%B8%D8%A7%D9%87%D8%B1_%D8%A8%D9%8A%D8%A8%D8%B1%D8%B3.JPG	test caption
1263	R	Shaykh al-Islam Ibn Taymiyyah	[Ibn Taymiyyah](https://en.wikipedia.org/wiki/Ibn_Taymiyyah) is probably the most influential scholar of Islam since Al-Ghazali. A [Hanbali](https://en.wikipedia.org/wiki/Hanbali) jurist, muhaddith, and theologian, he's considered a [mujaddid](https://en.wikipedia.org/wiki/Mujaddid). He participated in rallying the troops in the battles against the mongols and was imprisoned seven times for contraversial [fatwas](https://en.wikipedia.org/wiki/Fatwa). He wrote treaties on almost every Islamic science from the attributes of Allah to "Averting the Conflict between Reason and (religious) Tradition" and [a refutation of the christian doctrine](https://en.wikipedia.org/wiki/Al-Jaw%C4%81b_al-%E1%B9%A2a%E1%B8%A5%C4%AB%E1%B8%A5_li-man_baddala_d%C4%ABn_al-Mas%C4%ABh) and fanaa' al-Nar (the end of hellfire) and politics (Al-Siyasah al-Shar’iyah).	https://upload.wikimedia.org/wikipedia/commons/f/fb/%D8%AA%D8%AE%D8%B7%D9%8A%D8%B7_%D9%83%D9%84%D9%85%D8%A9_%D8%A7%D8%A8%D9%86_%D8%AA%D9%8A%D9%85%D9%8A%D8%A9.png	test caption
1280	S	Richest Man Ever	[Mansy Musa](https://en.wikipedia.org/wiki/Mansa_Musa)	https://upload.wikimedia.org/wikipedia/commons/e/ea/Catalan_Atlas_BNF_Sheet_6_Mansa_Musa.jpg	test caption
1349	S	Ibn Battuta Returns	[Ibn Battuta](https://en.wikipedia.org/wiki/Ibn_Battuta) returns to Morocco.	https://upload.wikimedia.org/wikipedia/commons/9/9c/Handmade_oil_painting_reproduction_of_Ibn_Battuta_in_Egypt%2C_a_painting_by_Hippolyte_Leon_Benett..jpg	test caption
1332	S	Ibn Khaldun	The first sociologist and modern historian [Ibn Khaldun](https://en.wikipedia.org/wiki/Ibn_Khaldun)	https://upload.wikimedia.org/wikipedia/commons/a/aa/Bust_of_Ibn_Khaldun_%28Casbah_of_Bejaia%2C_Algeria%29.jpg	test caption
1453	C	The Prophet's Prophecy	[The Conquest Of Constantinople](https://en.wikipedia.org/wiki/Fall_of_Constantinople)	https://upload.wikimedia.org/wikipedia/commons/c/c6/Gentile_Bellini_003.jpg	test caption
1500	C	The Admiral Of The Mediterranean	[Khayreddin Barbarossa](https://en.wikipedia.org/wiki/Hayreddin_Barbarossa)...	https://upload.wikimedia.org/wikipedia/commons/0/09/Barbarossa_Hayreddin_Pasha.jpg	test caption
1501	C	A Shia Dynasty In Persia	The [Safavids](https://en.wikipedia.org/wiki/Safavid_dynasty) rise to power in Persia.	https://upload.wikimedia.org/wikipedia/commons/4/40/Safavid_Flag.svg	test caption
1517	C	The End Of Mamluks	The last Mamluk Sultan Tuman bay II was cast into prison, and shortly after, hanged as a malefactor at the City Gate.	https://upload.wikimedia.org/wikipedia/commons/7/79/Djemaa_el_Fna.jpg	test caption
1525	C	Mughal Empire	[The Mughul Empire](https://en.wikipedia.org/wiki/Mughal_Empire)	https://upload.wikimedia.org/wikipedia/commons/1/1d/Taj_Mahal_%28Edited%29.jpeg	test caption
1798	C	Napoleon In The Middle East	[The French Campaign](https://en.wikipedia.org/wiki/French_campaign_in_Egypt_and_Syria)	https://upload.wikimedia.org/wikipedia/commons/9/99/Baron_Antoine-Jean_Gros-Battle_Pyramids_1810.jpg	test caption
1916	C	Slicing The Cake	[Sykes-Picot](https://en.wikipedia.org/wiki/Sykes%E2%80%93Picot_Agreement)	https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/MPK1-426_Sykes_Picot_Agreement_Map_signed_8_May_1916.jpg/1024px-MPK1-426_Sykes_Picot_Agreement_Map_signed_8_May_1916.jpg	test caption
1924	C	Atatürk Abolishes The Caliphate	The defeat of the Germans and the Ottomans in WWI had sever repurcussions...	https://upload.wikimedia.org/wikipedia/commons/f/f8/Abolition_of_the_Caliphate%2C_The_Last_Caliph%2C_Le_Petit_Journal_illustr%C3%A9%2C_16_March_1924.jpg	test caption
1950s	C	The Rise Of Nationalism	Gamal Abd el-Naser, et al. Nationalism gained grounds during the 20th century in the orient.	https://upload.wikimedia.org/wikipedia/commons/e/e1/Nasser_in_Mansoura%2C_1960.jpg	test caption
`

export default content;